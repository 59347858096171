<template>
  <section id="home">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="4"
      >
        <coupon-filters
          :brand-filter.sync="brandFilter"
          :merchant-filter.sync="merchantFilter"
          :brand-options="brandOptions"
          :merchant-options="merchantOptions"
        />
      </b-col>
      <b-col
        xl="4"
        md="4"
      >
        <coupon-statistics :data="data.couponStatistics" />
      </b-col>
      <b-col
        xl="4"
        md="4"
      >
        <coupon-usage-chart :data="data.couponUsage" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <coupon-daily-report :data="data.couponDaily" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import CouponFilters from './CouponFilters.vue'
import CouponStatistics from './CouponStatistics.vue'
import CouponUsageChart from './CouponUsageChart.vue'
import CouponDailyReport from './CouponDailyReport.vue'
import couponStoreModule from '../e-coupon/coupon/couponStoreModule'

export default {
  components: {
    BRow,
    BCol,

    CouponFilters,
    CouponStatistics,
    CouponUsageChart,
    CouponDailyReport,
  },
  data() {
    return {
      brandOptions: [],
      merchantOptions: [],
      data: {
        couponStatistics: [
          {
            icon: 'CreditCardIcon',
            color: 'light-success',
            title: '230k',
            subtitle: this.$i18n.t('Coupon'),
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'UserIcon',
            color: 'light-info',
            title: '8.549k',
            subtitle: this.$i18n.t('Customer'),
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'CheckIcon',
            color: 'light-danger',
            title: '1.423k',
            subtitle: this.$i18n.t('Redeem'),
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'XIcon',
            color: 'light-warning',
            title: '9745',
            subtitle: this.$i18n.t('Refund'),
            customClass: '',
          },
        ],
        couponUsage: {
          series: [80, 16, 4],
        },
        couponDaily: {
          years: ['2021', '2020', '2019'],
          price: '25,852',
          budget: '56,800',
          revenueReport: {
            series: [
              {
                name: 'Redeem',
                data: [2, 3, 6, 12, 2, 4, 22, 5, 14, 2, 3, 6, 12, 2, 4, 22, 5, 14, 4, 9],
              },
              {
                name: 'Refund',
                data: [-1, -1, 0, -2, 0, 0, -3, -1, -2, -1, -1, 0, -2, 0, 0, -3, -1, -2, 0, -3],
              },
            ],
          },
          budgetChart: {
            series: [
              {
                data: [200, 300, 600, 1200, 200, 400, 2200, 500, 1400],
              },
              {
                data: [100, 100, 0, 200, 0, 0, 300, 100, 200],
              },
            ],
          },
        },
      },
    }
  },
  mounted() {
    this.getDefaultCoupon()
  },
  methods: {
    getDefaultCoupon() {
      store
        .dispatch('coupon-store/getDefaultCoupon', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.brandOptions = response.data.brand_options
          this.merchantOptions = response.data.merchant_options
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
  },
  setup() {
    const brandFilter = ref(null)
    const merchantFilter = ref(null)

    const STORE_MODULE_NAME = 'coupon-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, couponStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const filterCoupon = JSON.parse(localStorage.getItem('filterCoupon'))
    if (filterCoupon !== null) {
      brandFilter.value = (filterCoupon.brand !== null) ? filterCoupon.brand : ''
      merchantFilter.value = (filterCoupon.merchant !== null) ? filterCoupon.merchant : ''
    }

    return {
      brandFilter,
      merchantFilter,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

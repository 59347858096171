<template>
  <b-card
    v-if="data"
    class="usage-card"
  >
    <b-row>
      <b-col cols="12">
        <b-card-title class="mb-1">
          Usage
        </b-card-title>
        <b-card-text class="text-muted font-small-2">
          <span class="font-weight-bolder">68.2%</span><span> more earnings than last month.</span>
        </b-card-text>
      </b-col>
    </b-row>
    <b-row style="margin-top: 1rem;">
      <b-col cols="12">
        <!-- chart -->
        <vue-apex-charts
          height="180"
          :options="earningsChart.chartOptions"
          :series="data.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: true },
          // comparedResult: [2, -3, 8],
          labels: ['Coupon', 'Redeem', 'Refund'],
          stroke: { width: 0 },
          colors: [$themeColors.success, $themeColors.danger, $themeColors.warning],
          grid: {
            padding: {
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 20,
                  },
                  value: {
                    offsetY: -20,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 20,
                    label: 'Coupon',
                    formatter() {
                      return '80%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 160,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 180,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 160,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 180,
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>

<template>
  <div>
    <b-col class="p-0">

      <b-card no-body>
        <b-card-header>
          <div>
            <b-card-title class="mb-1">
              {{ $t('Filters') }}
            </b-card-title>
          </div>
        <!-- datepicker -->
        </b-card-header>
        <b-card-body class="pt-0 pb-2 pl-2 pr-2">
          <b-row>
            <!-- Brand -->
            <b-col
              cols="12"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('Brand') }}</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="brandFilter"
                :options="brandOptions"
                class="w-100"
                :reduce="val => val.value"
                @input="(val) => $emit('update:brandFilter', val)"
              />
            </b-col>
          </b-row>
          <b-row style="margin-top: 0.6rem;">
            <!-- Merchant -->
            <b-col
              cols="12"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('Merchant') }}</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="merchantFilter"
                :options="merchantOptions"
                class="w-100"
                :reduce="val => val.value"
                @input="(val) => $emit('update:merchantFilter', val)"
              />
            </b-col>
          </b-row>
          <b-row style="margin-top: 0.8rem;">
            <!-- Date -->
            <b-col
              cols="12"
              class="mb-md-0 mt-0"
            >
              <b-form-group
                label-for="coupon-start-date"
                :label="$t('Start Date')"
              >
                <flat-pickr
                  v-model="rangePicker"
                  :config="{ mode: 'range', dateFormat: 'd F Y' }"
                  class="form-control"
                  :placeholder="$t('Start Date')"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </div>
</template>

<script>
import {
  BCard, BCardHeader,
  BCardTitle,
  BCardBody, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    vSelect,
    flatPickr,
  },
  props: {
    collapsedFilter: {
      type: Boolean,
      default: false,
    },
    brandFilter: {
      type: [String, null],
      default: null,
    },
    merchantFilter: {
      type: [String, null],
      default: null,
    },
    brandOptions: {
      type: Array,
      required: true,
    },
    merchantOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rangePicker: ['02 January 2021', '10 January 2021'],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.card {
  .card-title {
    font-size: 1.07rem;
  }
  .card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
